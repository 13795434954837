window.addEventListener('scroll', function () {
    const scroll = document.documentElement.scrollTop;
    const scrolltopButton = document.getElementsByClassName('scrolltop')[0];

    if (!scrolltopButton) return

    if (scroll > 2000) {
        scrolltopButton.classList.add('scrolltop--active');
    } else {
        scrolltopButton.classList.remove('scrolltop--active');
    }

    scrolltopButton.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });
});

window.copyCode = function(e) {
    // クリックしたボタンに紐づくコードの範囲の定義
    let code = e.closest('.code-block').querySelector('.rouge-code')

    // クリップボードにコードをコピーしてから、ボタンのテキストを変更する
    navigator.clipboard.writeText(code.innerText)
        .then(() => e.innerText = 'Copied')

    // 任意：コピーしたコードが選択されたようにする
    window.getSelection().selectAllChildren(code)
}
